import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { ProjectTypeInterface } from "@/types/project-type.type";
import { getProjectTypes } from "@/services/project-type.service";

@Module({
  dynamic: true,
  name: "projectTypeModule",
  store,
})
class ProjectTypeModule extends VuexModule {
  public projectTypes: ProjectTypeInterface[] = [];

  @MutationAction({ mutate: ["projectTypes"] })
  public async getProjectTypes() {
    const projectTypes = await getProjectTypes();
    return { projectTypes };
  }
}

export default getModule(ProjectTypeModule);
