import axios from "@/plugins/axios-setting";
import { QuotationInterface } from "@/types/quotation.type";

export function getQuotations(): Promise<QuotationInterface[]> {
  return axios.get("/api/quotation/");
}

export function getQuotationById(id: number): Promise<QuotationInterface> {
  return axios.get(`/api/quotation/${id}/`);
}

export function createQuotation(data: QuotationInterface): Promise<QuotationInterface> {
  return axios.post("/api/quotation/", data);
}

export function updateQuotation(data: QuotationInterface): Promise<QuotationInterface> {
  return axios.put(`/api/quotation/${data.id}/`, data);
}

export function deleteQuotation(id: number): Promise<void> {
  return axios.delete(`/api/quotation/${id}/`);
}
