import {
  CreateProjectMemberInterface,
  ProjectMemberInterface,
  ProjectRoleAnswerInterface,
  ProjectRolesInterface,
} from "@/types/project-member.type";
import axios from "@/plugins/axios-setting";

export function addProjectMember(projectUser: CreateProjectMemberInterface): Promise<ProjectMemberInterface> {
  return axios.post("/api/project-member/", projectUser);
}

export function updateProjectMember(projectUser: ProjectMemberInterface): Promise<ProjectMemberInterface> {
  return axios.patch(`/api/project-member/${projectUser.id}/`, projectUser);
}

export function deleteProjectMember(id: number): Promise<ProjectMemberInterface> {
  return axios.delete(`/api/project-member/${id}/`);
}

export function getProjectMembers(): Promise<ProjectMemberInterface[]> {
  return axios.get("/api/project-member/");
}

export function getMembersByProjectId(id: number): Promise<ProjectMemberInterface[]> {
  return axios.get(`/api/project-member/?project=${id}`);
}

export function getMembersById(id: number): Promise<ProjectMemberInterface> {
  return axios.get(`/api/project-member/${id}/`);
}

export function getProjectRoles(): Promise<ProjectRolesInterface> {
  return axios.get(`api/project-member/roles/`);
}

export function getProjectMembersInProject(id: number): Promise<ProjectMemberInterface> {
  return axios.get(`api/project-member/project/?id=${id}`);
}

export function validateProjectRole(projectId: number, roleName: string): Promise<boolean> {
  return axios.get(`api/project-member/is_role/?project=${projectId}&role=${roleName}`);
}

export function getCurrentUserRoleInProject(projectId: number): Promise<ProjectRoleAnswerInterface> {
  return axios.get(`/api/project-member/my_role/?project=${projectId}`);
}
