import Vue from "vue";
import Component from "vue-class-component";
import { humanizeDuration } from "@/utils/unitConverstion";

@Component
export class DurationHelperMixins extends Vue {
  public humanizeDuration(duration: number) {
    return humanizeDuration(duration);
  }

  public convertSecondsToDay(seconds: number) {
    const dayInSeconds = 8 * 60 * 60;
    return seconds / dayInSeconds;
  }

  public formatLeaveDuration(text: string) {
    switch (text) {
      case "full-day":
        return "Full-Day";
      case "half-morning":
        return "Half-Day Morning";
      case "half-afternoon":
        return "Half-Day Afternoon";
      default:
        return "Unknown";
    }
  }
}
