import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import { DurationTypeInterface } from "@/types/durationType.type";
import store from "@/store";
import { getDurationTypes, getDurationTypeById } from "@/services/duration.service";

interface IDurationTypeModule {
  durationTypes: DurationTypeInterface[];
  durationType: DurationTypeInterface | null;
}

@Module({
  dynamic: true,
  name: "duration",
  store,
})
class DurationType extends VuexModule {
  public durationTypes: DurationTypeInterface[] = [];
  public durationType: DurationTypeInterface = {} as DurationTypeInterface;

  @MutationAction({ mutate: ["durationTypes"] })
  public async getDurationTypes() {
    const durationTypes = await getDurationTypes();
    return { durationTypes };
  }

  @MutationAction({ mutate: ["durationType"] })
  public async getDurationTypeById(id: number) {
    const { ...durationType } = await getDurationTypeById(id);
    return { durationType };
  }
}

export const DurationTypeModule = getModule(DurationType);
