var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-class":_vm.addRowClasses,"sort-by":_vm.sort,"sort-desc":_vm.desc,"show-select":_vm.showSelect,"server-items-length":_vm.totalDataCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.clickedRow},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [(!item.lock)?_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"item.alertCount",fn:function(ref){
var item = ref.item;
return [_vm._t("alertCount",null,{"item":item})]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._t("startDate",[_vm._v(" "+_vm._s(item.startDate))],{"item":item})]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._t("firstName",null,{"item":item})]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_vm._t("position",null,{"item":item})]}},{key:"item.positionCharge",fn:function(ref){
var item = ref.item;
return [_vm._t("positionCharge",null,{"item":item})]}},{key:"item.durationLimit",fn:function(ref){
var item = ref.item;
return [_vm._t("durationLimit",null,{"item":item})]}},{key:"item.durationType",fn:function(ref){
var item = ref.item;
return [_vm._t("durationType",null,{"item":item})]}},{key:"item.sumDurationLimit",fn:function(ref){
var item = ref.item;
return [_vm._t("sumDurationLimit",null,{"item":item})]}},{key:"item.sumPositionCharge",fn:function(ref){
var item = ref.item;
return [_vm._t("sumPositionCharge",null,{"item":item})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._t("status",null,{"item":item})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._t("active",null,{"item":item})]}},{key:"item.billable",fn:function(ref){
var item = ref.item;
return [_vm._t("billable",null,{"item":item})]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_vm._t("download",null,{"item":item})]}},{key:"item.approvedByInfo",fn:function(ref){
var item = ref.item;
return [_vm._t("approvedByInfo",null,{"item":item})]}},{key:"item.projectDisplay",fn:function(ref){
var item = ref.item;
return [_vm._t("projectDisplay",[_vm._v(" "+_vm._s(item.projectDisplay))],{"item":item})]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_vm._t("clientName",[_vm._v(" "+_vm._s(item.clientName))],{"item":item})]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_vm._t("approved",[_vm._v(" "+_vm._s(item.approved))],{"item":item})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._t("action",null,{"item":item})]}},{key:"item.projectTags",fn:function(ref){
var item = ref.item;
return [(item.projectTags.length)?_c('v-tooltip',{attrs:{"content-class":"tag-tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',{staticClass:"pl-2 pr-2 mr-1",attrs:{"small":"","color":"#d6dbfd","text-color":"#0F2CAC"}},[_vm._v(" "+_vm._s(item.projectTags[0].name)+" ")]),_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.projectTags.length > 1),expression:"item.projectTags.length > 1"}],staticClass:"pl-1 pr-1",attrs:{"small":"","color":"#d6dbfd","text-color":"#0F2CAC"}},'v-chip',attrs,false),on),[_c('span',{staticStyle:{"letter-spacing":"-1px","font-size":"9px"}},[_vm._v(" +"+_vm._s(item.projectTags.length - 1)+" ")])])]}}],null,true)},[(item.projectTags.length > 1)?_c('span',_vm._l((item.projectTags.slice(1)),function(value,index){return _c('v-chip',{key:("hidden-tag-" + index),class:{ 'mr-1': index < item.projectTags.length - 2 },attrs:{"small":"","color":"#d6dbfd","text-color":"#0F2CAC"}},[_vm._v(" "+_vm._s(value.name)+" ")])}),1):_vm._e()]):_vm._e()]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._t("updated",null,{"item":item})]}},{key:"item.maximumUnit",fn:function(ref){
var item = ref.item;
return [_vm._t("maximumUnit",null,{"item":item})]}},{key:"item.totalDuration",fn:function(ref){
var item = ref.item;
return [_vm._t("totalDuration",null,{"item":item})]}},{key:"item.billedDuration",fn:function(ref){
var item = ref.item;
return [_vm._t("billedDuration",null,{"item":item})]}},{key:"item.approvedDuration",fn:function(ref){
var item = ref.item;
return [_vm._t("approvedDuration",null,{"item":item})]}},{key:"item.lock",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-cy":"timesheet-lock-icon"}},[(item.lock)?[_c('img',{staticStyle:{"opacity":"0.66"},attrs:{"src":require("@/assets/icons/lock.svg"),"height":"20px","alt":"lock-icon"}})]:[_c('img',{attrs:{"src":require("@/assets/icons/unlock.svg"),"height":"20px","alt":"unlock-icon"}})]],2)]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(typeof item.duration === 'number')?_c('span',[_vm._v(" "+_vm._s(_vm.humanizeDuration(item.duration))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatLeaveDuration(item.duration))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }