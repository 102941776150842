import Vue from "vue";
import Component from "vue-class-component";
import { UserModule } from "@/store/modules/user-module";

@Component
export class RoleMixin extends Vue {
  public get isAdmin() {
    return UserModule.isAdmin;
  }

  public get isGuest() {
    return UserModule.isGuest;
  }

  public get isEmployee() {
    return UserModule.isEmployee;
  }
}
