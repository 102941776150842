import { Action, getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  CreateProjectMemberInterface,
  ProjectMemberInterface,
  ProjectRolesInterface,
} from "@/types/project-member.type";
import { ProjectUserInterface } from "@/types/project.type";
import {
  addProjectMember,
  deleteProjectMember,
  getMembersByProjectId,
  updateProjectMember,
  getMembersById,
  getProjectRoles,
  getProjectMembersInProject,
  validateProjectRole,
} from "@/services/project-member.service";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import router from "@/router";

interface IProjectMember {
  projectMembers: ProjectUserInterface[];
}

@Module({
  dynamic: true,
  store,
  name: "project-member",
})
class ProjectMember extends VuexModule implements IProjectMember {
  public projectMembers: ProjectUserInterface[] = [];
  public projectMember: ProjectUserInterface = {} as ProjectUserInterface;
  public projectRoles: ProjectRolesInterface[] = [];
  public projectMembersInProject: ProjectMemberInterface[] = [];
  public isManager = false;
  public isWorker = false;
  public isViewer = false;

  @Action({ rawError: true })
  public async addProjectMember(req: CreateProjectMemberInterface) {
    try {
      await addProjectMember(req);
      await SnackbarModule.setSnack({
        color: "success",
        message: "Add Member successfully!",
      });
      await router.push({
        name: "Project Detail",
        params: { id: router.currentRoute.params.id.toString() },
      });
    } catch (e) {
      if (e.response.status === 400) {
        await SnackbarModule.setSnack({
          color: "error",
          message: "This user has already been assigned",
        });
      } else {
        await SnackbarModule.setSnack({
          color: "error",
          message: e.response.data.detail,
        });
      }
    }
  }
  @Action
  public async updateProjectMember(data: CreateProjectMemberInterface) {
    try {
      await updateProjectMember(data);
      await SnackbarModule.setSnack({
        color: "success",
        message: "Update Member successfully!",
      });
      await router.push({
        name: "Project Detail",
        params: { id: router.currentRoute.params.id.toString() },
      });
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
    }
  }

  @MutationAction({ mutate: ["projectMembers"], rawError: true })
  public async getMembersByProjectId(id: number) {
    const projectMembers = await getMembersByProjectId(id);
    return { projectMembers };
  }

  @MutationAction({ mutate: ["projectMember"], rawError: true })
  public async getMembersById(id: number) {
    const projectMember = await getMembersById(id);
    return { projectMember };
  }

  @MutationAction({ mutate: ["projectMembers"] })
  public async deleteProjectMember(id: number) {
    await deleteProjectMember(id);
    const projectMembers = { ...(this.state as IProjectMember) }.projectMembers;
    const deleteIndex = projectMembers.findIndex((value) => value.id === id);
    if (deleteIndex !== -1) {
      projectMembers.splice(deleteIndex, 1);
    }
    return { projectMembers };
  }

  @MutationAction({ mutate: ["projectRoles"], rawError: true })
  public async getProjectRoles() {
    const projectRoles = await getProjectRoles();
    return { projectRoles };
  }

  @MutationAction({ mutate: ["projectMembersInProject"], rawError: true })
  public async getProjectMemberByProject(id: number) {
    const projectMembersInProject = await getProjectMembersInProject(id);
    return { projectMembersInProject };
  }

  @MutationAction({ mutate: ["isManager"], rawError: true })
  public async getIsManager(projectId: number) {
    const isManager = await validateProjectRole(projectId, "manager");
    return { isManager };
  }
  @MutationAction({ mutate: ["isWorker"], rawError: true })
  public async getIsWorker(projectId: number) {
    const isWorker = await validateProjectRole(projectId, "worker");
    return { isWorker };
  }
  @MutationAction({ mutate: ["isViewer"], rawError: true })
  public async getIsViewer(projectId: number) {
    const isViewer = await validateProjectRole(projectId, "viewer");
    return { isViewer };
  }
}
export const ProjectMemberModule = getModule(ProjectMember);
