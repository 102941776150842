import axios from "@/plugins/axios-setting";
import { ClientInterface } from "@/types/client.type";

export function getClients(): Promise<ClientInterface[]> {
  return axios.get("/api/client/");
}

export function createClient(data: ClientInterface): Promise<ClientInterface> {
  return axios.post("/api/client/", data);
}

export function updateClient(data: ClientInterface): Promise<ClientInterface> {
  return axios.put(`/api/client/${data.id}/`, data);
}

export function getClientById(id: number): Promise<ClientInterface> {
  return axios.get(`/api/client/${id}/`);
}

export function deleteClient(id: number): Promise<void> {
  return axios.delete(`/api/client/${id}/`);
}
