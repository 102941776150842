import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import { ClientInterface } from "@/types/client.type";
import store from "@/store";
import { getClients, createClient, getClientById, updateClient, deleteClient } from "@/services/client.service";

interface IClientModule {
  clients: ClientInterface[];
  client: ClientInterface | null;
}
@Module({
  dynamic: true,
  name: "client",
  store,
})
class ClientModule extends VuexModule {
  public clients: ClientInterface[] = [];
  public client: ClientInterface | null = null;

  @MutationAction({ mutate: ["clients"] })
  public async getClients() {
    const clients: ClientInterface[] = await getClients();
    return { clients };
  }

  @MutationAction({ mutate: ["clients"] })
  public async addClient(client: ClientInterface) {
    const newClient: ClientInterface = await createClient(client);
    const clients: ClientInterface[] = [...(this.state as IClientModule).clients];
    clients.push(newClient);
    return { clients };
  }

  @MutationAction({ mutate: ["client"] })
  public async getClientById(id: number) {
    const { ...client }: ClientInterface = await getClientById(id);
    return { client };
  }

  @MutationAction({ mutate: ["clients", "client"] })
  public async updateClient(client: ClientInterface) {
    const updatedClient: ClientInterface = await updateClient(client);
    const clients: ClientInterface[] = [...(this.state as IClientModule).clients];
    const updatedIndex: number = clients.findIndex((item) => item.id === client.id);
    clients[updatedIndex] = updatedClient;
    return { clients, client: updatedClient };
  }

  @MutationAction({ mutate: ["clients", "client"] })
  public async deleteClient(id: number) {
    await deleteClient(id);
    const clients: ClientInterface[] = [...(this.state as IClientModule).clients];
    const deletedIndex: number = clients.findIndex((item) => item.id === id);
    clients.splice(deletedIndex, 1);
    return { clients, client: null };
  }
}

export default getModule(ClientModule);
