






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DeleteButton extends Vue {
  @Prop({
    type: Function,
  })
  private click!: () => void;

  @Prop({
    type: String,
    default: "",
  })
  private readonly text!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private readonly disabled!: boolean;
}
