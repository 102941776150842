























































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { mixins } from "vue-class-component";

@Component({
  components: {},
})
export default class TableComponent extends mixins(DurationHelperMixins) {
  @Prop({
    type: Function,
    default() {
      return;
    },
  })
  private clickedRow!: () => void;

  @Prop({
    type: Function,
    default() {
      return;
    },
  })
  private optionsChangeCallback!: (option: object) => void;

  @Prop({
    default: [],
  })
  private readonly headers!: [];

  @Prop({
    default() {
      return [];
    },
  })
  private readonly items!: [];

  @Prop({
    type: String,
    default: "",
  })
  private readonly search!: string;

  @Prop({
    type: String,
    default: "",
  })
  private readonly sort!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  private readonly desc!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private readonly showSelect!: boolean;

  @Prop({
    type: Number,
    default: undefined,
  })
  private readonly totalDataCount!: number | undefined;

  private selected: [] = [];
  private options = {};

  @Watch("items")
  private onItemsChange() {
    this.selected = [];
  }

  private addRowClasses(item: { lock: boolean }) {
    if (item.lock) {
      return "disable-row";
    }
  }

  @Watch("selected")
  private watchSelected(newVal: object) {
    this.$emit("on-selected", newVal);
  }

  @Watch("options")
  private watchOptions(newOptions: object) {
    this.optionsChangeCallback(newOptions);
  }
}
