import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import { getQuotationById, createQuotation, updateQuotation, deleteQuotation } from "@/services/quotation.service";
import { QuotationInterface } from "@/types/quotation.type";
import { DataTableHeader } from "vuetify";

import store from "@/store";

interface IQuotationModule {
  quotations: QuotationInterface[];
  quotation: QuotationInterface | {};
}

@Module({
  dynamic: true,
  name: "quotation",
  store,
})
class QuotationModule extends VuexModule implements IQuotationModule {
  public quotations: QuotationInterface[] = [];
  public quotation: QuotationInterface = {} as QuotationInterface;
  public positionHeader: DataTableHeader[] = [
    {
      text: "Name",
      value: "position",
    },
    {
      text: "Price Per Unit",
      value: "positionCharge",
    },
    {
      text: "Duration Limit",
      value: "durationLimit",
    },
    {
      text: "Action",
      value: "action",
    },
  ];

  public quotationHeader: DataTableHeader[] = [
    {
      text: "Quotation Number",
      value: "number",
    },
    {
      text: "Service Number",
      value: "serviceNumber",
    },
    {
      text: "Modified",
      value: "updated",
    },
    {
      text: "Action",
      value: "action",
    },
  ];

  @MutationAction({ mutate: ["quotations"], rawError: true })
  public async addQuotation(quotation: QuotationInterface) {
    const newQuotation: QuotationInterface = await createQuotation(quotation);
    const quotations: QuotationInterface[] = [...(this.state as IQuotationModule).quotations];
    quotations.push(newQuotation);
    return { quotations };
  }

  @MutationAction({ mutate: ["quotation"], rawError: true })
  public async getQuotationById(id: number) {
    const quotation: QuotationInterface = await getQuotationById(id);
    return { quotation };
  }

  @MutationAction({ mutate: ["quotations", "quotation"], rawError: true })
  public async updateQuotation(quotation: QuotationInterface) {
    const updatedQuotation: QuotationInterface = await updateQuotation(quotation);
    const quotations: QuotationInterface[] = [...(this.state as IQuotationModule).quotations];
    const updatedIndex: number = quotations.findIndex((item) => item.id === quotation.id);
    quotations[updatedIndex] = updatedQuotation;
    return { quotations, quotation: updatedQuotation };
  }

  @MutationAction({ mutate: ["quotations", "quotation"], rawError: true })
  public async deleteQuotation(id: number) {
    await deleteQuotation(id);
    const quotations: QuotationInterface[] = [...(this.state as IQuotationModule).quotations];
    const deletedIndex: number = quotations.findIndex((item) => item.id === id);
    quotations.splice(deletedIndex, 1);
    return { quotations, quotation: null };
  }
}
export default getModule(QuotationModule);
