






import { Component, Prop, Vue } from "vue-property-decorator";
import { VuetifyBreadcrumbItem } from "@/types/vuetify.type";
import { ProjectInterface } from "@/types/project.type";

@Component
export default class ProjectBreadcrumb extends Vue {
  @Prop({
    required: true,
  })
  private readonly projectData!: ProjectInterface;

  @Prop({
    required: true,
  })
  private readonly currentPageName!: string;

  // private items: VuetifyBreadcrumbItem[] = []
  private defaultItems: VuetifyBreadcrumbItem[] = [
    {
      text: "Projects",
      exact: true,
      to: {
        name: "Project List",
      },
    },
  ];

  private get items() {
    const items = [...this.defaultItems];
    if (this.projectData.id) {
      items.push({
        text: this.projectData.name,
        exact: true,
        to: {
          name: "Project Detail",
          params: { id: this.projectData.id.toString() },
        },
      });
    }
    items.push({
      text: this.currentPageName,
    });
    return items;
  }
}
