












import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import ClientForm from "@/components/client/ClientForm.vue";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import Divider from "@/components/Divider.vue";
import Header from "@/components/Header.vue";

@Component({
  components: {
    ClientForm,
    Divider,
    Header,
  },
})
export default class Dialog extends Vue {
  @Prop({
    default: "",
    type: String,
  })
  private title!: string;

  private dialog: boolean = false;

  public openDialog() {
    this.dialog = true;
  }

  private simpleCloseDialog() {
    this.dialog = false;
  }

  private closeDialog(success: boolean) {
    if (success) {
      SnackbarModule.setSnack({
        color: "success",
        message: "Edit user successfully!",
      });
    } else {
      SnackbarModule.setSnack({
        color: "error",
        message: "Failed to edit user",
      });
    }
    this.dialog = false;
  }
}
