










































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { ClientInterface } from "@/types/client.type";
import ClientModule from "@/store/modules/client-module";
import { RoleMixin } from "@/mixins/role-mixin";
import CloseButton from "@/components/buttons/CloseButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import Divider from "@/components/Divider.vue";

@Component({
  components: {
    SaveButton,
    Divider,
    CloseButton,
  },
})
export default class ClientForm extends Mixins(RoleMixin) {
  private clientData: ClientInterface = {
    id: null,
    name: "",
  };

  private formValid: boolean = false;

  private clientRules = [(v: any) => !!v || "Client name is required"];

  @Prop({
    default: true,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    type: Boolean,
  })
  private readonly dialog!: boolean;

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly clientId!: number | null | string;

  @Prop({
    type: Function,
  })
  private afterSubmit!: (success: boolean) => void;

  @Prop({
    type: Function,
  })
  private simpleClose!: () => void;

  public clearForm(): void {
    this.clientData = {
      id: null,
      name: "",
    };
  }

  @Watch("clientId")
  private async onPropertyChanged(value: number | null | string) {
    await this.setClient();
  }

  @Watch("dialog")
  private async onDialogChanged() {
    if (this.dialog) {
      await this.setClient();
    }
  }

  private async saveClient() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.formValid) {
      if (!this.editMode) {
        try {
          await ClientModule.addClient(this.clientData);
          this.afterSubmit(true);
        } catch {
          this.afterSubmit(false);
        }
      } else {
        try {
          await ClientModule.updateClient(this.clientData);
          this.afterSubmit(true);
        } catch {
          this.afterSubmit(false);
        }
      }
      this.clearForm();
    }
  }

  private async created() {
    await this.setClient();
  }

  private async setClient() {
    if (this.editMode) {
      await ClientModule.getClientById(this.clientId as number);
      this.clientData = { ...(ClientModule.client as ClientInterface) };
    }
  }
}
