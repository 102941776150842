


















































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import TagsModule from "@/store/modules/tags-module";
import { ProjectTagInterface, TagInterface } from "@/types/project.type";

@Component
export default class TagForm extends mixins(DurationHelperMixins) {
  @Prop()
  private tags!: ProjectTagInterface[] | TagInterface[];

  @Prop()
  private selectedTags!: any[];

  @Prop({
    type: String,
  })
  private itemValueKey!: string;

  @Prop({
    type: Function,
  })
  private removeTag!: (item: any) => void;

  @Prop({
    default: false,
    type: Boolean,
  })
  private small!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  private allowCreateTag!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  private readonly!: boolean;

  private search: any = "";

  private get mutatedSelectedTags() {
    return this.selectedTags;
  }

  private set mutatedSelectedTags(value) {
    this.$emit("tag-change", value);
  }

  private async createNewTags() {
    await TagsModule.addTag({ name: this.search });
    await TagsModule.getTags();
    this.search = "";
  }
}
