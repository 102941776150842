import { round } from "lodash";
import { DurationTypeInterface } from "@/types/durationType.type";
import moment from "moment";

const daySecConst = 8 * 60 * 60;

export function dayToSecond(day: number) {
  return day * daySecConst;
}

export function secondToDay(second: number) {
  const day = second / daySecConst;
  return round(day, 2);
}

export function secondToDurationUnit(second: number | null, durationType?: DurationTypeInterface | null) {
  if (!second || !durationType) {
    return 0;
  }
  const durationConstant = durationType.seconds;
  return second / durationConstant;
}

export function durationUnitToSecond(value: number | null, durationType?: DurationTypeInterface | null) {
  if (!value || !durationType) {
    return 0;
  }
  const durationConstant = durationType.seconds;
  return value * durationConstant;
}

export function getDurationTypeById(durationTypeList: DurationTypeInterface[], durationId?: number | null) {
  if (!durationId && durationId !== 0) {
    return null;
  }
  return durationTypeList.find((dt: DurationTypeInterface) => dt.id === durationId);
}

export function humanizeDuration(duration: number, minimal = false): string {
  if (duration >= 28800) {
    // more than 8 hours
    const days = Math.floor(duration / 28800);
    const pluralForm = days > 1 ? "s" : "";
    const leftover = duration % 28800;
    let additionalHours = "";
    if (leftover) {
      additionalHours = " & " + moment.duration(leftover, "seconds").humanize();
    }
    if (minimal) {
      let fraction = moment.duration(leftover, "seconds").hours();
      fraction = fraction / 8.0; // 8 hours per day

      return `${days + round(fraction, 1)}`;
    }
    return `${days} day${pluralForm}${additionalHours}`;
  } else if (duration === 0) {
    if (minimal) {
      return "0";
    }
    return `-`;
  } else if (duration < 60) {
    if (duration === 1) {
      return "1 second";
    }
    return `${duration} seconds`;
  }

  if (minimal) {
    let fraction = moment.duration(duration, "seconds").hours();
    fraction = fraction / 8.0; // 8 hours per day

    return `${round(fraction, 1)}`;
  }
  return moment.duration(duration, "seconds").humanize();
}
