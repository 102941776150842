



















































































































































import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { isEmpty } from "lodash";
import { ProjectInterface, TagInterface } from "@/types/project.type";
import { ClientInterface } from "@/types/client.type";
import ClientModule from "@/store/modules/client-module";
import { ProjectModule } from "@/store/modules/project-module";
import { FormInterface } from "@/types/component.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { RoleMixin } from "@/mixins/role-mixin";
import isEmail from "validator/lib/isEmail";
import ProjectTypeModule from "@/store/modules/project-type-module";
import { ProjectTypeInterface } from "@/types/project-type.type";
import CloseButton from "@/components/buttons/CloseButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import Divider from "@/components/Divider.vue";
import Header from "@/components/Header.vue";
import { VuetifyForm } from "@/types/vuetify.type";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import TagsModule from "@/store/modules/tags-module";
import { createProject } from "@/services/project.service";
import TagForm from "@/components/project/TagForm.vue";

@Component({
  components: {
    SaveButton,
    Divider,
    CloseButton,
    Header,
    DeleteButton,
    TagForm,
  },
})
export default class ProjectForm extends Mixins(RoleMixin) implements FormInterface {
  get clients(): ClientInterface[] {
    return ClientModule.clients;
  }

  private get projectTypes(): ProjectTypeInterface[] {
    return ProjectTypeModule.projectTypes;
  }

  private get tags(): TagInterface[] {
    return TagsModule.tags;
  }

  @Ref()
  private readonly form!: VuetifyForm;

  private valid: boolean = true;

  private saving: boolean = false;

  @Prop({
    default: true,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    type: Function,
  })
  private afterSubmit!: () => void;

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly projectId!: number | null | string;

  @Prop({
    type: Object,
    default: null,
  })
  private projectDetail!: ProjectInterface;

  @Prop({
    type: Function,
  })
  private simpleClose!: () => void;

  private projectNameRule = [(v: any) => !!v || "Project name is required"];

  private projectClientRule = [(v: any) => !!v || "Client is required"];

  private projectContactPersonNameRule = [(v: any) => !!v || "Contact Person Name is required"];

  private projectContactPersonEmailRule = [(v: any) => isEmpty(v) || isEmail(v) || "E-mail must be valid"];

  private projectTypeRule = [(v: any) => !!v || "Project Type is required"];

  private menu: boolean = false;

  private search = "";

  public clearForm(): void {
    this.projectDetail = {
      id: undefined,
      client: undefined,
      name: "",
      remark: "",
      contactPersonName: "",
      contactPersonEmail: "",
      billedDuration: 0,
      totalDuration: 0,
      startDate: "",
      projectType: "",
      active: true,
      canEdit: false,
      tags: [],
      autoApproveTimesheet: false,
      alertCount: 0,
      billable: false,
      pendingDuration: 0,
      rejectedDuration: 0,
      approvedDuration: 0,
    };
  }

  private async clickSave() {
    if (!this.form.validate()) {
      return;
    }
    this.afterSubmit();
  }

  private async created() {
    await ClientModule.getClients();
    await ProjectTypeModule.getProjectTypes();
    await TagsModule.getTags();
  }

  private removeTag(item: any) {
    const index = this.projectDetail.tags.indexOf(item.id);
    if (index >= 0) {
      this.projectDetail.tags.splice(index, 1);
    }
  }

  private updateSelectedTag(value: any[]) {
    this.projectDetail.tags = value;
  }

  private async saveProject() {
    if (this.form.validate()) {
      this.saving = true;
      if (this.editMode) {
        await this.updateProjectDetail();
      } else {
        await this.createNewProject();
      }
      this.afterSubmit();
      this.saving = false;
    } else {
      await SnackbarModule.setSnack({
        color: "error",
        message: "Please fill in all the fields",
      });
    }
  }
  private async createNewProject() {
    try {
      const project = await createProject(this.projectDetail);
      if (project.id) this.$router.push({ path: `project/${project.id.toString()}` });
      else this.simpleClose();
    } catch (exp) {
      const error = exp.toJSON();
      await SnackbarModule.setSnack({
        color: "error",
        message: error.message,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Create New project successfully!",
    });
  }

  private async updateProjectDetail() {
    try {
      await ProjectModule.updateProject(this.projectDetail);
    } catch (e) {
      await SnackbarModule.setSnack({
        color: "error",
        message: e.response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Update project successfully!",
    });
  }
}
