





































































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { QuotationInterface } from "@/types/quotation.type";
import QuotationModule from "@/store/modules/quotation-module";
import { DataTableHeader } from "vuetify";
import { DurationTypeInterface } from "@/types/durationType.type";
import { DurationTypeModule } from "@/store/modules/duration-module";
import { getDurationTypeDisplay } from "@/utils/duration";
import { PositionInterface, ProjectInterface, ProjectPositionInterface } from "@/types/project.type";
import ProjectPositionForm from "@/components/project/ProjectPositionForm.vue";
import { ProjectModule } from "@/store/modules/project-module";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import ProjectBreadcrumb from "@/components/project/ProjectBreadcrumb.vue";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { RoleMixin } from "@/mixins/role-mixin";
import { ErrorModule } from "@/store/modules/error";
import NotFound from "@/components/NotFound.vue";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import TableComponent from "@/components/TableComponent.vue";
import SearchField from "@/components/text-field/SearchField.vue";
import CreateButton from "@/components/buttons/CreateButton.vue";
import Header from "@/components/Header.vue";
import Dialog from "@/components/Dialog.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ProjectPositionForm,
    ConfirmationDialog,
    ProjectBreadcrumb,
    NotFound,
    TableComponent,
    SearchField,
    CreateButton,
    Header,
    Dialog,
  },
})
export default class QuotationDetail extends Mixins(DurationHelperMixins, RoleMixin) {
  get quotation(): QuotationInterface {
    return QuotationModule.quotation;
  }

  get positionHeader(): DataTableHeader[] {
    return QuotationModule.positionHeader;
  }

  get durationTypes(): DurationTypeInterface[] {
    return DurationTypeModule.durationTypes;
  }

  get project(): ProjectInterface {
    return ProjectModule.project;
  }
  get isNotFound(): boolean {
    return ErrorModule.isNotFound;
  }
  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }
  public $refs!: {
    projectPositionForm: HTMLFormElement;
    deleteDialog: HTMLFormElement;
    dialog: HTMLFormElement;
  };

  private search: string = "";

  private editMode: boolean = false;

  private positionDialog: boolean = false;

  private getDurationTypeDisplay = getDurationTypeDisplay;

  // project id
  @Prop({
    required: true,
  })
  private readonly id!: number;

  // quotation id
  @Prop({
    required: true,
  })
  private readonly uid!: number;

  private positionId: number | null | string = 1;

  private positionForm: object = {};

  private projectPosition: ProjectPositionInterface = {
    positionCharge: 0,
    durationLimit: 0,
    project: this.id,
    noDurationLimit: false,
    durationType: 0,
    quotation: this.uid,
    position: {} as PositionInterface,
  };

  private updateSearch(txt: string) {
    this.search = txt;
  }

  @Watch("positionDialog")
  private projectDialogChanged(val: boolean) {
    if (!val) {
      this.closeDialog();
    }
  }

  private async created() {
    await QuotationModule.getQuotationById(this.uid as number);
    await ProjectModule.getProjectById(this.id as number);
    await DurationTypeModule.getDurationTypes();
    await ProjectMemberModule.getIsManager(Number(this.$route.params.id));
    this.positionForm = Object.assign({}, this.projectPosition);
  }

  private closeDialog() {
    this.$refs.dialog.simpleCloseDialog();
    // Delay resetting form for 300 ms
    setTimeout(() => {
      this.positionForm = Object.assign({}, this.projectPosition);
      this.editMode = false;
      const resetAllValidation = this.$refs.projectPositionForm.$refs.form;
      resetAllValidation.resetValidation();
    }, 300);
  }

  private openEditPositionDialog(projectPosition: ProjectPositionInterface) {
    this.editMode = true;
    this.positionForm = Object.assign({}, projectPosition);
    this.$refs.dialog.openDialog();
  }

  private openAddDialog() {
    this.editMode = false;
    this.$refs.dialog.openDialog();
  }

  private openDeletePositionDialog(positionId: number) {
    this.positionId = positionId;
    this.$refs.deleteDialog.openDialog();
    return;
  }

  private async deleteProject() {
    try {
      await ProjectModule.deleteProjectPosition(this.positionId as number);
      this.quotation.positions = this.quotation.positions!.filter((each) => each.id !== this.positionId);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete position successfully!",
    });
  }
}
