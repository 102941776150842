





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PositionModule } from "@/store/modules/position-module";
import { PositionInterface } from "@/types/project.type";
import { isEmpty } from "lodash";

@Component
export default class PositionSelector extends Vue {
  get positions() {
    return PositionModule.sortedPositions;
  }

  public $refs!: {
    comboBox: HTMLFormElement;
  };

  @Prop()
  public readonly initValue!: PositionInterface;

  @Prop({
    required: true,
  })
  public readonly editing!: boolean;
  @Prop()
  public readonly rules!: [];
  @Prop()
  public readonly viewMode!: boolean;

  @Prop({
    type: [String],
    default: "",
  })
  public readonly errorMessage?: string;

  public position: PositionInterface | string = { name: "", id: -1 } as PositionInterface;

  private search = "";

  private findPosition(position: string) {
    return this.positions.find((p) => p.name === position);
  }

  private async fetchAndAssignPosition() {
    if (typeof this.position === "string" && !!(this.position as string).trim()) {
      const positionString = (this.position as string).trim();
      if (!this.findPosition(positionString)) {
        await this.createNewPosition();
      }
      this.position = this.findPosition(positionString) as PositionInterface;
    }
  }

  private async onEnterPosition() {
    this.$refs.comboBox.blur();
    this.$nextTick(() => {
      this.fetchAndAssignPosition();
    });
  }

  @Watch("initValue")
  private watchInitValue(val: PositionInterface) {
    this.loadInitValue();
  }

  private async created() {
    await PositionModule.getPositions();
  }

  private mounted() {
    this.loadInitValue();
  }

  private loadInitValue() {
    if (isEmpty(this.initValue)) {
      this.position = { name: "", id: -1 } as PositionInterface;
      return;
    }
    this.position = this.initValue;
  }

  private async createNewPosition() {
    await PositionModule.addPosition({ name: this.search });
    await PositionModule.getPositions();
  }

  @Watch("position")
  private watchPosition() {
    this.$emit("select-position", this.position);
  }
}
