import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { PositionInterface, CreatePositionInterface } from "@/types/project.type";
import { getPositions, addPosition } from "@/services/position.service";

@Module({
  dynamic: true,
  name: "position",
  store,
})
class Position extends VuexModule {
  public positions: PositionInterface[] = [];

  get sortedPositions() {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    this.positions.sort((a, b) => collator.compare(a.name, b.name));
    return this.positions;
  }

  @MutationAction({ mutate: ["positions"] })
  public async getPositions() {
    const positions: PositionInterface[] = await getPositions();
    return { positions };
  }

  @Action({ rawError: true })
  public async addPosition(newPosition: CreatePositionInterface) {
    const pos = await addPosition(newPosition);
    this.SET_POSITIONS(pos);
  }
  @Mutation
  private SET_POSITIONS(position: PositionInterface) {
    this.positions.push(position);
  }
}

export const PositionModule = getModule(Position);
