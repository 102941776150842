














import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SearchField extends Vue {
  private text: string = "";

  @Prop({
    type: Function,
  })
  private updateValue!: (txt: string) => void;

  @Watch("text")
  private onSearchChanged(value: string, oldValue: string) {
    this.updateValue(value);
  }
}
