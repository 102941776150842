






















































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { CUProjectPositionInterface, PositionInterface, ProjectPositionInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import { FormInterface } from "@/types/component.type";
import { DurationTypeInterface } from "@/types/durationType.type";
import { DurationTypeModule } from "@/store/modules/duration-module";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import QuotationModule from "@/store/modules/quotation-module";
import ProjectForm from "@/components/project/ProjectForm.vue";
import PositionSelector from "@/components/project/PositionSelector.vue";
import { durationUnitToSecond, getDurationTypeById, secondToDurationUnit } from "@/utils/unitConverstion";
import { RoleMixin } from "@/mixins/role-mixin";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import SaveButton from "@/components/buttons/SaveButton.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ProjectForm,
    ConfirmationDialog,
    PositionSelector,
    SaveButton,
    CloseButton,
  },
})
export default class ProjectPositionForm extends Mixins(DurationHelperMixins, RoleMixin) implements FormInterface {
  get durationTypes(): DurationTypeInterface[] {
    return DurationTypeModule.durationTypes.filter((type) => type.seconds === 28800);
    // Only allow 1 day cause there's a bug
  }

  get isConvertible(): boolean {
    return this.editMode;
  }

  public positionErrorMessage = "";
  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }
  public position = "";
  public $refs!: {
    form: HTMLFormElement;
  };

  @Prop({
    type: Function,
  })
  private simpleClose!: () => void;

  @Prop({
    default: false,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  private readonly isActive!: boolean;

  @Prop({
    type: Object,
    default: null,
  })
  private projectPosition!: ProjectPositionInterface;

  @Prop({
    type: Function,
  })
  private afterSubmit!: () => void;

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly quotationId!: number | null | string;

  @Prop({
    type: [Boolean],
    default: null,
  })
  private readonly viewMode!: boolean;

  private positionNameRule = [(v: any) => !!v || "Position name is required"];

  private positionChargeRule = [(v: PositionInterface) => !!v || "Position Charge is required"];

  private zeroAndNoneEmptyPricePerUnitRule = [(v: string) => !!String(v) || "Price Per Unit is required"];

  private valid: boolean = true;

  public clearForm(): void {
    this.projectPosition = {
      position: {} as PositionInterface,
      positionCharge: 0,
      noDurationLimit: false,
      durationLimit: 0,
      project: 0,
      durationType: 0,
      quotation: 0,
    };
  }

  public changeSecondToTypeUnit() {
    const durationTypeId = this.projectPosition.durationType;
    const durationType = getDurationTypeById(this.durationTypes, durationTypeId);
    const durationLimitInSecond = this.projectPosition.durationLimit;
    const durationInUnit = secondToDurationUnit(durationLimitInSecond, durationType);
    this.projectPosition.durationLimit = !durationInUnit ? durationLimitInSecond : durationInUnit;
  }

  public getDurationInSecond(durationLimitValue: number | null, durationTypeId?: number | null) {
    const durationType = getDurationTypeById(this.durationTypes, durationTypeId);
    return durationUnitToSecond(durationLimitValue, durationType);
  }

  @Watch("isConvertible")
  private watchIsConvertible(val: boolean) {
    if (val) {
      this.changeSecondToTypeUnit();
    }
  }

  @Watch("isActive")
  private watchIsActive(val: boolean) {
    if (!val) {
      this.positionErrorMessage = "";
    }
  }

  private async created() {
    await DurationTypeModule.getDurationTypes();
  }

  private mounted() {
    if (this.isConvertible) {
      this.changeSecondToTypeUnit();
    }
  }
  private async savePosition() {
    const validation = this.$refs.form.validate();
    const positionMissing = !this.projectPosition.position || this.projectPosition.position.id === -1;
    this.positionErrorMessage = positionMissing ? "Position is required." : "";
    if (validation && !positionMissing) {
      if (this.projectPosition.noDurationLimit) {
        this.projectPosition.durationLimit = null;
        this.projectPosition.durationType = null;
      }
      if (this.editMode) {
        await this.updatePositionDetail();
      } else {
        await this.createNewPosition();
      }
      await QuotationModule.getQuotationById(this.quotationId as number);
      this.afterSubmit();
    } else {
      await SnackbarModule.setSnack({
        color: "error",
        message: "Please fill in all the fields",
      });
    }
  }

  private async createNewPosition() {
    try {
      const data = {
        ...this.projectPosition,
        position: this.projectPosition.position.id,
      } as CUProjectPositionInterface;
      data.durationLimit = this.getDurationInSecond(data.durationLimit, data.durationType);
      data.position = this.projectPosition.position.id;
      await ProjectModule.addProjectPosition(data);
    } catch ({ response }) {
      let errorMsg = "";
      if (response.data.detail) {
        errorMsg = response.data.detail;
      } else if (response.data.durationLimit) {
        errorMsg = "Limitation of duration limit cannot exceed 10 years";
      } else {
        errorMsg = "Something went wrong. Please contact your maintainers ";
      }
      await SnackbarModule.setSnack({
        color: "error",
        message: errorMsg,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Create New position successfully!",
    });
  }

  private async updatePositionDetail() {
    try {
      const data = {
        ...this.projectPosition,
        position: this.projectPosition.position.id,
      } as CUProjectPositionInterface;
      data.durationLimit = this.getDurationInSecond(data.durationLimit, data.durationType);
      data.position = this.projectPosition.position.id;
      await ProjectModule.updateProjectPosition(data);
    } catch ({ response }) {
      let errorMsg = "";
      if (response.data.detail) {
        errorMsg = response.data.detail;
      } else if (response.data.durationLimit) {
        errorMsg = "Limitation of duration limit cannot exceed 10 years";
      } else {
        errorMsg = "Something went wrong. Please contact your maintainers ";
      }
      await SnackbarModule.setSnack({
        color: "error",
        message: errorMsg,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Update position successfully!",
    });
  }
  private onSelectPosition(data: PositionInterface) {
    this.projectPosition.position = data;
  }

  private durationTypeRequiredIfHasDurationLimit(input: any) {
    if (this.projectPosition.noDurationLimit) {
      return true;
    }
    return !!input || "Duration Type is required";
  }

  private zeroAndNoneEmptyDurationLimitIfHasDurationLimit(input: any) {
    if (this.projectPosition.noDurationLimit) {
      return true;
    }
    if (
      (this.projectPosition.durationType === 1 && input > 3650) ||
      (this.projectPosition.durationType === 2 && input > 7300)
    ) {
      return "Duration limit must not exceed 10 years";
    }
    return !!String(input) || "Duration limit is required";
  }
}
