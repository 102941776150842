




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Header extends Vue {
  @Prop({
    default: "",
    type: String,
  })
  private header!: string;
}
