import { Action, getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { getTags, addTag } from "@/services/tags.service";
import { TagInterface } from "@/types/project.type";

@Module({
  dynamic: true,
  name: "tags",
  store,
})
class TagsModule extends VuexModule {
  public tags: TagInterface[] = [];

  @MutationAction({ mutate: ["tags"] })
  public async getTags() {
    const tags: [] = await getTags();
    return { tags };
  }

  @Action({ rawError: true })
  public async addTag(newTag: object) {
    await addTag(newTag);
  }
}

export default getModule(TagsModule);
