






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CloseButton extends Vue {
  @Prop({
    type: Function,
  })
  private click!: () => void;

  @Prop({
    type: String,
    default: "Close",
  })
  private text!: () => string;
}
