










































import { Component, Prop, Vue } from "vue-property-decorator";
import { DialogInterface } from "@/types/component.type";
import CloseButton from "@/components/buttons/CloseButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";

@Component({
  components: {
    SaveButton,
    CloseButton,
    DeleteButton,
  },
})
export default class ConfirmationDialog extends Vue implements DialogInterface {
  private dialog: boolean = false;

  @Prop({
    type: Function,
    required: false,
  })
  private readonly action!: () => void;

  @Prop({
    type: String,
    default: "Confirm Deletion",
  })
  private dialogHeader: string | undefined;

  @Prop({
    type: String,
    default: "Are you sure you want to delete this item?",
  })
  private dialogText: string | undefined;

  @Prop({
    type: String,
    default: "Delete",
  })
  private confirmText: string | undefined;

  public openDialog() {
    this.dialog = true;
  }

  public closeDialog() {
    this.dialog = false;
  }

  private async performDelete() {
    await this.action();
    this.closeDialog();
  }
}
