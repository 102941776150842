













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SaveButton extends Vue {
  @Prop({
    type: Function,
  })
  private click!: () => void;

  @Prop({
    type: String,
    default: "Save",
  })
  private readonly text!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private readonly disabled!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  private readonly block!: boolean;
}
